<script>
import DaylogService from "@/services/DaylogService";
import dayjs from "dayjs";
import VCal from "./components/VCal";
import { EventBus } from "@/libraries/EventBus";

export default {
    components: { VCal },
    data() {
        return {
            loading: true,
            logs: [],
            year: null,
            month: null
        };
    },
    async created() {
        EventBus.$on("daylogs-change", this.fetchData);
        this.year = dayjs().format("YYYY");
        this.month = dayjs().format("MM");
        await this.fetchData();
        this.loading = false;
    },
    beforeDestroy() {
        EventBus.$off("daylogs-change", this.fetchData);
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true; 
                const { data } = await DaylogService.GET_myData(
                    this.year,
                    this.month
                );
                this.logs = data.data;
            } catch (e) {
                console.log("fd, e", e);
            }
            this.loading = false; 
        },
        async showModal(date) {
            console.log("Eventbus", date);
            EventBus.$emit("register-daylog", date);
        },
        changeMonth(offset) {
            const newDate = dayjs(`${this.year}-${this.month}-01`).add(
                offset,
                "month"
            );
            this.year = newDate.year();
            this.month = newDate.format("MM"); // Ensure zero-padded month
            this.fetchData();
        }
    }
};
</script>

<template>
    <div class="">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else>
                            <div
                                class="row px-4 mb-4 d-flex align-items-center"
                            >
                                <div class="col">
                                    <base-button @click="changeMonth(-1)">
                                        <i class="fas fa-arrow-left"></i>
                                    </base-button>
                                </div>
                                <div class="col text-center">
                                    <h1 class="m-0 p-0">
                                        {{ month }}.{{ year }}
                                    </h1>
                                </div>
                                <div class="col text-right">
                                    <base-button @click="changeMonth(1)">
                                        <i class="fas fa-arrow-right"></i>
                                    </base-button>
                                </div>
                            </div>

                            <v-cal
                                :year="year"
                                :month="month"
                                :logs="logs"
                                @show-day="showModal"
                            />
                        </template>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
