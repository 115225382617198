<script>
import VTime from "@/components/Clockin/VTime";
import dayjs from "dayjs";

export default {
    props: {
        month: {},
        year: {},
        logs: {}
    },
    components: { VTime },
    data() {
        return {
            daysOfWeek: [
                this.$t("dateRangePicker.Mon"),
                this.$t("dateRangePicker.Tue"),
                this.$t("dateRangePicker.Wed"),
                this.$t("dateRangePicker.Thu"),
                this.$t("dateRangePicker.Fri"),
                this.$t("dateRangePicker.Sat"),
                this.$t("dateRangePicker.Sun")
            ],
            calendarDays: []
        };
    },
    mounted() {
        this.generateCalendar(this.month, this.year);
    },
    methods: {
        generateCalendar(month, year) {
            const firstDayOfMonth = dayjs(`${year}-${month}-01`);
            const daysInMonth = firstDayOfMonth.daysInMonth();
            const firstWeekday = firstDayOfMonth.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

            let startOffset = firstWeekday === 0 ? 6 : firstWeekday - 1; // Adjust to start on Monday
            let daysArray = new Array(startOffset).fill(null); // Empty slots before 1st

            for (let i = 1; i <= daysInMonth; i++) {
                daysArray.push(i);
            }

            while (daysArray.length % 7 !== 0) {
                daysArray.push(null); // Fill remaining slots
            }

            this.calendarDays = daysArray;
        },
        fmt(y, m, d) {
            return dayjs(`${y}-${m}-${d}`).format("YYYY-MM-DD");
        }
    }
};
</script>

<template>
    <div class="calendar-container">
        <!-- Weekday headers -->
        <div class="calendar-row calendar-header">
            <div
                v-for="day in daysOfWeek"
                :key="day"
                class="calendar-cell header"
            >
                {{ day }}
            </div>
        </div>
        <!-- Calendar days -->
        <div class="calendar-row">
            <div
                v-for="(day, index) in calendarDays"
                :key="index"
                class="calendar-cell"
                :class="{ empty: !day }"
                @click="day ? $emit('show-day', fmt(year, month, day)) : false"
            >
                <div v-if="day">
                    <div class="d-day">{{ day || "" }}</div>
                    <div
                        v-if="logs[fmt(year, month, day)]['daylog']"
                        class="d-block day-contents"
                    >
                        <div
                            :class="{
                                'text-warning':
                                    logs[fmt(year, month, day)]['daylog'][
                                        'status'
                                    ] === 1
                            }"
                        >
                            <i class="far fa-clock fa-fw"></i>
                            <v-time
                                :time="
                                    logs[fmt(year, month, day)]['daylog'][
                                        'time_start'
                                    ]
                                "
                            />
                            -
                            <v-time
                                v-if="
                                    logs[fmt(year, month, day)]['daylog'][
                                        'time_stop'
                                    ]
                                "
                                :time="
                                    logs[fmt(year, month, day)]['daylog'][
                                        'time_stop'
                                    ]
                                "
                            />
                            <template v-else
                                ><i class="far fa-question-square"></i
                            ></template>
                        </div>

                        <div>
                            <i class="far fa-sigma fa-fw"></i>
                            {{
                                logs[fmt(year, month, day)]["daylog"][
                                    "total_time"
                                ] | toTime
                            }}

                            <i class="far fa-pause-circle"></i>
                            {{
                                logs[fmt(year, month, day)]["daylog"][
                                    "pause_time"
                                ] | toTime
                            }}

                            <i class="far fa-cog fa-spin"></i>
                            {{
                                logs[fmt(year, month, day)]["daylog"][
                                    "work_time"
                                ] | toTime
                            }}
                        </div>
                        <div>
                            {{ $t("daylogs.status") }}:
                            <template
                                v-if="
                                    logs[fmt(year, month, day)]['daylog'][
                                        'status'
                                    ] === 0
                                "
                            >
                                <i class="far fa-empty-set"></i>
                            </template>
                            <template
                                v-else-if="
                                    logs[fmt(year, month, day)]['daylog'][
                                        'status'
                                    ] === 1
                                "
                            >
                                <i class="far fa-lock-open"></i>
                            </template>
                            <template
                                v-else-if="
                                    logs[fmt(year, month, day)]['daylog'][
                                        'status'
                                    ] === 2
                                "
                            >
                                <i class="far fa-lock"></i>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.calendar-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .calendar-row {
        display: flex;
        flex-wrap: wrap;
    }

    .day-contents {
        font-size: 13px;
        text-align: center;
    }

    .calendar-header {
        font-weight: bold;
        text-align: center;
        background: #f8f9fa;
    }

    .d-day {
        position: absolute;
        left: 4px;
        top: 4px;
        font-size: 14px;
        font-weight: bold;
    }

    .calendar-cell {
        &:hover {
            border: 1px solid $primary;
            background: $primary;
            color: white !important;
            cursor: pointer;
        }
        flex: 1;
        min-width: 14.285%; /* 100% / 7 for equal spacing */
        max-width: 14.285%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ddd;
        background-color: #fff;
        position: relative;
    }

    .header {
        background: $gray-500;
        color: $default;
        font-weight: bold;
    }

    .empty {
        background: #f0f0f0;
        border: none;
    }
}
</style>
