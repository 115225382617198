<script>
export default {
    props: {
        time: {}
    },
    computed: {
        fmt() {
            const [h, m, s] = this.time.split(":");

            console.log(h, m, s);

            return this.constructTime(h, m);
        }
    },
    methods: {
        constructTime(hours, minutes) {
            // Ensure valid numbers and pad with zeroes
            let h = parseInt(hours, 10);
            let m = parseInt(minutes, 10);

            if (isNaN(h) || h < 0) h = 0;
            if (h > 23) h = 23; // Cap at 23
            if (isNaN(m) || m < 0) m = 0;
            if (m > 59) m = 59; // Cap at 59

            return `${String(h).padStart(2, "0")}:${String(m).padStart(
                2,
                "0"
            )}`;
        }
    }
};
</script>

<template>
    <span>
        {{ fmt }}
    </span>
</template>
